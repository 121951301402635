/* You can add global styles to this file, and also import other style files */
/*@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";*/

/*html{
  height: 100%;
}*/

body {
  margin: 0;
  /*height: 100%;*/
}

h1, h2 {
  text-align: center;
}

.input-group {
  display: flex;
  align-items: stretch;
}

.input-group-field {
  margin-right: 0;
}

.input-group .input-group-button {
  margin-left: 0;
  border: none;
}

.input-group .mat-flat-button {
  border-radius: 0;
}

.upowa-color-primary {
  background-color: #f39301;
  color: #fff;
}
.upowa-color-last {
  background-color: #6a4e38;
  color: #fff;
}

.red-color {
  color: #cb3429;
}

.green-color{
  color: #17b01f;
}

.margin {
  margin: 20px;
}

.padding {
  padding: 10px;
}

.p-d {
  padding-bottom: 5px;
}

.responsive-item {
  width: 100%;
  height: auto;
}

.img-padding {
  padding: 15px;
}

.responsive-item-maw {
  width: 100%;
  max-width: 100px;
  height: auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid green;
  height: 100%;
}

.add-button {
  position: fixed;
  bottom: 55px;
  right: 10px;
}
.delete-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.close{
  float:right;
}
.bold {
  font-weight: bold;
}

button.close{
  padding:0;
  background-color:transparent;
  border:0;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none
}
a.close.disabled{
  pointer-events:none
}

.make-overflow-answer {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100% !important;
  max-height: 100% !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #f39301 !important;
  border-radius: 5px;
}

.errorMessage {
  color: #cb3429;
}

.container-home {
  min-height: 100%;
  width: 100%;
}

.p-10 {
  padding: 10px;
}

.form {
  min-width: 150px;
  width: 100%;
}

.form-element {
  width: 100%;
}

.button {
  width: 100%;
  padding: 10px;
}

.radio-btn {
  padding: 0 5px 0 5px;
}

.transparent-bg {
  background-color: transparent !important;
}

.home-btn {
  padding-top: 5px;
  color: #fff;
  font-size: large;
  height: 40px;
  text-shadow: 2px 2px grey;
}

.txt_shadow {
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px white;
}

.mx {
  border: 5px solid #FFFF00;
  display: flex;
  justify-content: center;
}

.parent-super {
  height: 100%;
}

.parent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-wrap{
  white-space: pre-wrap
}

.form-title {
  margin: 5px 0;
  text-align: center;
  color: #8a96a3;
  padding-bottom: 10px;
  font-weight: bold;
}

.app-toolbar {
  position: sticky;
  position: -webkit-sticky; /* For macOS/iOS Safari */
  top: 0; /* Sets the sticky toolbar to be on top */
  z-index: 100000; /* Ensure that your app's content doesn't overlap the toolbar */
}
.app-task {
  position: sticky;
  position: -webkit-sticky; /* For macOS/iOS Safari */
  margin-top: 56px; /* Sets the sticky toolbar to be on top */
  z-index: 100000; /* Ensure that your app's content doesn't overlap the toolbar */
}

.mgs-top {
  margin-top: 230px;
}

.question-with-border {
  padding: 15px;
  border-style: groove;
  border-radius: 15px;
  text-align: justify;
  margin-bottom: 8px;
  opacity: 1;
  animation: fade 2s linear;
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

 /*Flex Layout css block*/

